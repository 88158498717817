*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.25rem;
}

body {
    background-color: #f2f1ed;
    color: #de4848;
}

.top,
.bottom,
.flip-card .top-flip,
.flip-card .bottom-flip {
    height: .75em;
    line-height: 1;
    padding: .25em;
    overflow: hidden;
}

.top,
.flip-card .top-flip {
    background-color: #f7f7f7;
    border-top-right-radius: .1em;
    border-top-left-radius: .1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bottom,
.flip-card .bottom-flip {
    background-color: white;
    display: flex;
    align-items: flex-end;
    border-bottom-right-radius: .1em;
    border-bottom-left-radius: .1em;
}

.container {
    display: flex;
    gap: .5em;
    justify-content: center;
}

.container-segment {
    display: flex;
    flex-direction: column;
    gap: .1em;
    align-items: center;
}

.segment {
    display: flex;
    gap: .1em;
}

.segment-title {
    font-size: 1rem;
}

.flip-card {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .2);
    border-radius: .1em;
    width: 100%;
    transition: transform 0.25s ease-in-out;
}
.flipping {
    transform: rotateX(90deg);
}